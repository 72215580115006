import React, { useContext, useEffect, useRef } from 'react';
import { Context as RegistrationContext } from '../providers/RegistrationProvider';
import { Context as RegistrationAdminContext } from '../providers/RegistrationAdminProvider';
import AssociationList from '../components/content/associations/AssociationList';
import HeaderView from '../components/common/HeaderView';
import AppCard from '../components/common/AppCard';
import { getResponsiveStyle as rs } from '../helpers/styles';
import { useMediaQueryIndex } from '../helpers/hooks';

const RegistrationsView = () => {
  const mqIndex = useMediaQueryIndex();
  const hasFetched = useRef(false);
  const { state, fetchRegAssociations } = useContext(RegistrationContext);
  const { state: adminState } = useContext(RegistrationAdminContext);
  const isLoading = !hasFetched.current || state.regAssociations.loading;
  const viewableRegistrations = state.regAssociations.value.filter(
    (a) =>
      !a.sandboxEnabled ||
      adminState.regAssociations.value.some(
        (s) => s.pkRegAssociation === a.pkRegAssociation
      )
  );
  const openRegistrationAssociations = viewableRegistrations.filter(
    (a) => a.hasOpenRegistrations
  );
  const closedRegistrationAssociations = viewableRegistrations.filter(
    (a) => !a.hasOpenRegistrations
  );

  useEffect(() => {
    fetchRegAssociations({});
    hasFetched.current = true;
  }, []);

  return (
    <HeaderView
      maxViewWidth={1100}
      title="Leagues & Clubs"
      titleSize={2}
      viewProps={{ gap: rs([10, 10, 40], mqIndex) }}
    >
      <AppCard
        style={{
          marginTop: rs([0, 0, 0, 20], mqIndex),
          borderRadius: rs([0, 0, 0, 4], mqIndex)
        }}
      >
        <AssociationList
          associations={openRegistrationAssociations}
          isLoading={isLoading}
          title="Ongoing Registrations"
        />
      </AppCard>

      {!isLoading && closedRegistrationAssociations.length > 0 && (
        <AppCard style={{ borderRadius: rs([0, 0, 0, 4], mqIndex) }}>
          <AssociationList
            associations={closedRegistrationAssociations}
            isLoading={isLoading}
            title="Closed Registrations"
          />
        </AppCard>
      )}
    </HeaderView>
  );
};

RegistrationsView.propTypes = {};

export default RegistrationsView;
